import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { routeOptimiser } from "../API/api";

const TravellingSalesmanProblem: React.FC = () => {
  const [addresses, setAddresses] = useState<string[]>([]);
  const [optimisedRoute, setOptimisedRoute] = useState<string[]>([]);
  const [currentAddress, setCurrentAddress] = useState<string>("");
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editAddress, setEditAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");

  // HANDLES CLICKS AND CHANGES
  const handleAddAddress = () => {
    if (currentAddress.trim() !== "" && addresses.length < 21) {
      setAddresses([...addresses, currentAddress]);
      setCurrentAddress("");
    }
  };
  // handle pressing enter while in textfield
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission or default action
      handleAddAddress();
    }
  };
  const handleEditAddress = (index: number) => {
    setEditIndex(index);
    setEditAddress(addresses[index]);
  };

  const handleSaveEdit = () => {
    if (editIndex !== null && editAddress.trim() !== "") {
      const updatedAddresses = [...addresses];
      updatedAddresses[editIndex] = editAddress;
      setAddresses(updatedAddresses);
      setEditIndex(null);
      setEditAddress("");
    }
  };

  const handleDeleteAddress = (index: number) => {
    const updatedAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(updatedAddresses);
  };

  const handleOptimiseRouteButton = () => {
    setLoading(true);
    callRouteOptimiser(addresses);
  };

  //API CALL
  const callRouteOptimiser = async (addresses: String[]) => {
    try {
      const response = await routeOptimiser(addresses);
      const responseMessage = JSON.stringify(response)
      if (responseMessage.includes("InvalidAddresses")){
        setErrMsg("Invalid Addresses: "+response.InvalidAddresses.join(", "))
        setLoading(false);
      } else {
        const optimisedAddresses = response.Success;
        setOptimisedRoute(optimisedAddresses.slice(0, -1));
        setLoading(false);
        setErrMsg("")
      }
      console.log(`Response ${response.InvalidAddresses}`)
      
    } catch (err) {
      setLoading(false);
      const errorMessage = String(err)
      if (errorMessage.includes("slice")){
        setErrMsg("You have not entered enough addresses to optimise route")
      } else {
        setErrMsg(String(err));
      }
      console.log(`Error ${err}`);
      //setErrMsg(String(err));
    }
  };

  //USE EFFECTS
  useEffect(() => {
    console.log("Addresses: ", addresses);
  }, [addresses]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: 2,
      }}
    >
      <Stack>
        <Typography
          variant="h4"
          sx={{
            padding: 2,
            fontFamily: "poppins",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "solid",
          }}
        >
          Addreses
        </Typography>
        <List>
          {addresses.map((address, index) => (
            <ListItem key={index}>
              <ListItemText primary={address} />
              <IconButton
                color="primary"
                onClick={() => handleEditAddress(index)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => handleDeleteAddress(index)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Dialog open={editIndex !== null} onClose={() => setEditIndex(null)}>
          <DialogTitle>Edit Address</DialogTitle>
          <DialogContent>
            <TextField
              label="Edit Address"
              value={editAddress}
              onChange={(e) => setEditAddress(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditIndex(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Stack direction="row" spacing={1}>
          <TextField
            label="Enter Address"
            value={currentAddress}
            onChange={(e) => setCurrentAddress(e.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={handleAddAddress}>
            Add
          </Button>
        </Stack>

        <IconButton color="primary" onClick={handleAddAddress}>
          <AddIcon />
        </IconButton>
        <Button
          variant="contained"
          onClick={handleOptimiseRouteButton}
          disabled={loading}
        >
          {loading ? "Calculating..." : "Optmise Route"}
        </Button>
        {errMsg.length !== 0 && <Typography color="error">{errMsg}</Typography>}

        {optimisedRoute.length > 0 && (
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
              mt: 2,
            }}
          >
            <Typography variant="h3">Optimised Route</Typography>
            {optimisedRoute.map((item, index) => {
              return <Typography>{index + 1 + ". " + item}</Typography>;
            })}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default TravellingSalesmanProblem;
