import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import TravellingSalesmanProblem from "../../TravellingSalesmanProblem/TravellingSalesmanProblem";
import MenuBar from "../MenuBar";
import { Helmet } from "react-helmet";

const RouteOptimiser: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
       <Helmet>
        <title>Mango Web Designs - Route Optimiser</title>
        <meta name="description" content="Route Optimiser for calculating shortest route to multiple addresses" />
        <meta name="keywords" content="route, optimise, address, travelling" />
        <meta property="og:title" content="Mango Web Designs - Route Optimiser" />
        <meta property="og:description" content="Route Optimiser for calculating shortest route to multiple addresses" />
        <meta property="og:type" content="website" />
      </Helmet>
      <MenuBar />
      <Stack sx={{
            mt: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
        <Typography variant="h1" sx={{padding: 3, fontFamily: 'poppins', alignSelf: 'center'}}>
          Route Optimiser
        </Typography>
        <Box
          
        >
          <TravellingSalesmanProblem />
        </Box>
      </Stack>
    </Box>
  );
};

export default RouteOptimiser;
