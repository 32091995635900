import axios, { AxiosResponse } from "axios";
import { Address, ResponseSuccess } from "../Types/types";

export const server_url = 'https://y9fzofmeqd.execute-api.ap-southeast-2.amazonaws.com/mangowebdesigns/';
export const routeOptimiserAPIUrl = 'mangowebdesigns/routeopti';


export const routeOptimiser = (adddresses: String[]): Promise<ResponseSuccess> => {
    return axios.post<String[]>(`${server_url+routeOptimiserAPIUrl}`, adddresses)
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        console.error("There was an error fetching the entries!", error);
        throw error
      })
  }